import prismicDOM from 'prismic-dom'
import linkResolver from './link-resolver'
import { djb2 } from '~/utils/hash'

const Elements = prismicDOM.RichText.Elements

const heading1 = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.heading1 || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<h1 class="text-size-h1-mobile md:text-size-h1-tablet lg:text-size-h1-desktop mb-6 md:mb-10 last:mb-0 ${spacingClasses}">${innerContent}</h1>`
}

const heading2 = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.heading2 || ''
  const innerContent = `${content || ''}${children.join('')}`
  const hash = djb2(innerContent)
  return `<h2 class="text-size-h2-mobile md:text-size-h2-tablet lg:text-size-h2-desktop mb-6 md:mb-10 last:mb-0 ${spacingClasses}" id="${hash}">${innerContent}</h2>`
}

const heading3 = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.heading3 || ''
  const innerContent = `${content || ''}${children.join('')}`
  const hash = djb2(innerContent)
  return `<h3 class="text-size-h3-mobile md:text-size-h3-tablet lg:text-size-h3-desktop mb-6 md:mb-10 last:mb-0 ${spacingClasses}" id="${hash}">${innerContent}</h3>`
}

const heading4 = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.heading4 || ''
  const innerContent = `${content || ''}${children.join('')}`
  const hash = djb2(innerContent)
  return `<h4 class="text-size-h4-mobile md:text-size-h4-tablet lg:text-size-h4-desktop mb-8 last:mb-0 ${spacingClasses}" id="${hash}">${innerContent}</h4>`
}

const heading5 = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.heading5 || ''
  const innerContent = `${content || ''}${children.join('')}`
  const hash = djb2(innerContent)

  return `<h5 class="text-size-h5-mobile md:text-size-h5-tablet lg:text-size-h5-desktop mb-8 last:mb-0 ${spacingClasses}" id="${hash}">${innerContent}</h5>`
}

const paragraph = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.paragraph || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<p class="font-roboto text-size-p-mobile mb-5.5 last:mb-0 ${spacingClasses}">${innerContent}</p>`
}

const preformatted = (
  element,
  content,
  children,
  customHTMLClassConfig = {}
) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.preformatted || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<pre class="mb-5.5 last:mb-0 ${spacingClasses}">${innerContent}</pre>`
}

const list = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.list || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<ul class="pl-8 list-none mb-5.5 last:mb-0 ${spacingClasses}">${innerContent}</ul>`
}

const listItem = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.listItem || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<li class="list-disc text-size-p-mobile last:mb-0 ${spacingClasses}">${innerContent}</li>`
}

const oList = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.oList || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<ol class="pl-8 list-none mb-5.5 last:mb-0 ${spacingClasses}">${innerContent}</ol>`
}

const oListItem = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.oListItem || ''
  const innerContent = `${content || ''}${children.join('')}`
  return `<li class="list-disc text-size-p-mobile mb-5.5 last:mb-0 ${spacingClasses}">${innerContent}</li>`
}

const image = (element, content, children, customHTMLClassConfig = {}) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const spacingClasses = customElementConfig.image || ''
  return `<img class="mb-5.5 last:mb-0 ${spacingClasses}" src="${element.url}" alt="${element.alt}" copyright="${element.copyright}" />`
}

export const hyperlink = (
  element,
  content,
  children,
  customHTMLClassConfig = {}
) => {
  const customElementConfig =
    (!!customHTMLClassConfig && customHTMLClassConfig) || {}
  const colorAndSpacingClasses = customElementConfig.hyperlink || ''
  const url = prismicDOM.Link.url(element.data, linkResolver)
  const innerContent = `${content || ''}${!content ? children.join('') : ''}`

  const target = element.data.target
    ? `target="'${element.data.target}'" rel="noopener"`
    : ''

  const dataAttr = element.data.link_type === 'Document' ? 'data-nuxt-link' : ''

  return `<a href="${url}" class="text-azure-darker no-underline hover:underline last:mb-0 ${colorAndSpacingClasses}" ${target} ${dataAttr}>${innerContent}</a>`
}

export default (type, ...args) => {
  // headings
  if (type === Elements.heading1) {
    return heading1(...args)
  }
  if (type === Elements.heading2) {
    return heading2(...args)
  }
  if (type === Elements.heading3) {
    return heading3(...args)
  }
  if (type === Elements.heading4) {
    return heading4(...args)
  }
  if (type === Elements.heading5) {
    return heading5(...args)
  }

  // text
  if (type === Elements.paragraph) {
    return paragraph(...args)
  }
  if (type === Elements.preformatted) {
    return preformatted(...args)
  }

  // lists
  if (type === Elements.list) {
    return list(...args)
  }
  if (type === Elements.listItem) {
    return listItem(...args)
  }
  if (type === Elements.oList) {
    return oList(...args)
  }
  if (type === Elements.oListItem) {
    return oListItem(...args)
  }

  // media
  if (type === Elements.image) {
    return image(...args)
  }

  // hyperlink
  if (type === Elements.hyperlink) {
    return hyperlink(...args)
  }
}
